import { Controller } from "@hotwired/stimulus";
import Trix from "trix";
import * as templates from "./templates";

const BlockAttributes = {
  heading: [
    {
      tagName: "h1",
      terminal: true,
      breakOnReturn: true,
      group: false
    },
    {
      tagName: "h2",
      terminal: true,
      breakOnReturn: true,
      group: false
    },
    {
      tagName: "h3",
      terminal: true,
      breakOnReturn: true,
      group: false
    },
    {
      tagName: "h4",
      terminal: true,
      breakOnReturn: true,
      group: false
    },
    {
      tagName: "h5",
      terminal: true,
      breakOnReturn: true,
      group: false
    },
    {
      tagName: "h6",
      terminal: true,
      breakOnReturn: true,
      group: false
    },
  ],
};

export default class extends Controller {
  connect() {
    this.loadModAttributes();

    addEventListener('trix-before-initialize', this.attachMods.bind(this), { once: true });
  }

  loadModAttributes() {
    Object.entries(BlockAttributes).forEach(([prefix, tags]) => {
      Array.from(tags).forEach((options, idx) => {
        Trix.config.blockAttributes[`${prefix}${idx + 1}`] = options;
      });
    });
  }

  attachMods(e) {
    this.editor = e.target;
    this.modifyToolbar();
  }

  modifyToolbar() {
    this.removeOriginalButtons();
    this.insertToolbarButtons();
    this.insertToolbarDialogs();
  }

  removeOriginalButtons() {
    this.buttonGroupBlockTools.removeChild(this.originalHeadingButton);
  }

  insertToolbarButtons() {
    this.buttonGroupBlockTools.insertAdjacentHTML("afterbegin", templates.headingButtonTemplate);
  }

  insertToolbarDialogs() {
    this.dialogsElement.insertAdjacentHTML("beforeend", templates.dialogHeadingTemplate);
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]");
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=block-tools]");
  }

  get originalHeadingButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=heading1]");
  }

  get toolbarElement() {
    return this.editor.toolbarElement;
  }
}
