const defaultDialogBtnClasses = [
  'trix-button',
  'trix-button--dialog',
  'trix-button--icon',
];

const dialogHeadingTemplate = `
<div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading">
  <div class="trix-dialog__link-fields">
    <input type="text" name="x-heading" class="trix-dialog-hidden__input" data-trix-input>
    <div class="trix-button-group">
      <button type="button"
        class="${defaultDialogBtnClasses.join(" ")} trix-button--icon-mod-h2"
        data-trix-attribute="heading2"
        data-trix-method="setAttribute">H2</button>
      <button type="button"
        class="${defaultDialogBtnClasses.join(" ")} trix-button--icon-mod-h3"
        data-trix-attribute="heading3"
        data-trix-method="setAttribute">H3</button>
      <button type="button"
        class="${defaultDialogBtnClasses.join(" ")} trix-button--icon-mod-h4"
        data-trix-attribute="heading4"
        data-trix-method="setAttribute">H4</button>
      <button type="button"
        class="${defaultDialogBtnClasses.join(" ")} trix-button--icon-mod-h5"
        data-trix-attribute="heading5"
        data-trix-method="setAttribute">H5</button>
      <button type="button"
        class="${defaultDialogBtnClasses.join(" ")} trix-button--icon-mod-h6"
        data-trix-attribute="heading6"
        data-trix-method="setAttribute">H6</button>
    </div>
  </div>
</div>`;

const headingButtonTemplate = `
<button type="button"
  class="trix-button trix-button--icon trix-button--icon-heading-1"
  data-trix-action="x-heading"
  title="Heading"
  tabindex="-1">Heading</button>`;

export {
  dialogHeadingTemplate,
  headingButtonTemplate,
};
