import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "resources"];

  connect() {
    this.template = this.templateTarget.content.cloneNode(true);
    this.count = this.resourcesTarget.childElementCount;
  }

  add(e) {
    e.preventDefault();
    e.stopPropagation();

    const template = document.importNode(this.templateTarget.content, true);
    const inputs = template.querySelectorAll('input');

    this.count += 1;

    for (let i = 0; i < inputs.length; i += 1) {
      const input = inputs[i];

      input.name = this._bumpObjId(input.name);
      input.id = this._bumpObjId(input.id);
    }

    this.resourcesTarget.appendChild(template);
  }

  _bumpObjId(str) {
    return str.replace(/\d/, this.count);
  }
}
