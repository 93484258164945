import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preview", "button"];

  static values = { labelName: String, imageUrl: String, missingImageUrl: String };

  connect() {
    this.buttonTarget.innerHTML = "Choose " + this.labelNameValue;

    if (this.imageUrlValue) {
      this.setPreviewImage(this.imageUrlValue);
    }
    else {
      this.previewTarget.src = this.missingImageUrlValue;
    }
  }

  show(e) {
    const imageUrl = window.URL.createObjectURL(e.target.files[0]);
    this.setPreviewImage(imageUrl);
  }

  setPreviewImage(imageUrl) {
    this.buttonTarget.innerHTML = "Change " + this.labelNameValue;
    this.previewTarget.src = imageUrl;
  }
}
