import { Controller } from '@hotwired/stimulus';

import { notifyError, notifySuccess } from "../lib/notifications";

export default class extends Controller {
  static classes = ['selectDisabled', 'submitDisabled'];

  static targets = ['categorySelect', 'orderRtsReasonsList', 'selectContainer', 'submit'];

  static values = { indexUrl: String };

  reload() {
    fetch(this.indexUrlValue)
      .then((response) => response.text())
      .then((html) => {
        this.orderRtsReasonsListTarget.innerHTML = html;
        this.categorySelectTarget.selectedIndex = 0;
        this.toggleVisibility();
      });
  }

  categorySelected() {
    return this.categorySelectTarget.selectedIndex > 0;
  }

  change() {
    this.toggleVisibility();
  }

  toggleVisibility() {
    this.submitTarget.classList.toggle(this.submitDisabledClass, !this.categorySelected());
    this.selectContainerTarget.classList.toggle(this.selectDisabledClass, !this.categorySelected());
  }

  deleteSuccess() {
    notifySuccess('RTS Reason deleted');

    this.reload();
  }

  deleteFailure() {
    notifyError('Failed to delete RTS Reason');
  }

  submitSuccess() {
    notifySuccess('RTS Reason added');

    this.reload();
  }

  submitFailure() {
    notifyError('Failed to add RTS Reason');
  }
}
