import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slider"];

  connect() {
    this.sliderTarget.breakpoints = this.breakpoints;
    this.sliderTarget.initialize();
  }

  get breakpoints() {
    return {
      "375": { "slidesPerView": 1.5, "slidesPerGroup": 1 },
      "768": { "slidesPerView": 3.5, "slidesPerGroup": 1 },
      "1024": { "slidesPerView": 3.5, "slidesPerGroup": 1 },
    };
  }
}
