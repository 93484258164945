import Rails from '@rails/ujs';
import $ from 'jquery';
import 'javascripts/lib/compat/_jquery_global';
import {Logger} from "./logger";

export class Component {
  constructor(hookOrEl, options) {
    this.options = options || {};
    this.logger = Logger;
    this.logger.enabled = (this.config.env !== 'production');
    this.$el = this._$elFor(hookOrEl);
  }

  hook(name) {
    return this.$el.find(this.selectorForHook(name));
  }

  // noinspection JSMethodCanBeStatic
  get data() {
    return Component.data;
  }

  // noinspection JSMethodCanBeStatic
  get config() {
    return Component.config;
  }

  didLoad() {
    this.logger.info('Override didLoad to implement your component');
  }

  _$elFor(obj) {
    if (obj instanceof $) {
      return obj;
    } else if (typeof obj === 'string') {
      return $(this.selectorForHook(obj));
    } else {
      try {
        return $(obj);
      } catch (e) {
        this.logger.warn("Must pass a hook (ex: 'btn-submit') or a DOM element / jQuery object");
        throw e;
      }
    }
  }

  selectorForHook(name) {
    return "[data-js-hook='" + name + "']";
  }

  static get data() {
    return window.preloadData || {};
  }

  static get config() {
    return (this.data && this.data.application && this.data.application.config) || {};
  }

  static load(ComponentClass, adminLayout=false) {
    // Until we opt everything into turbo, we continue to use @rails/ujs
    Rails.start();
    if (adminLayout) {
      // TODO: HyperJS has seems to have a need for $(document).ready()
      $(document).ready(this._load(ComponentClass));
    } else {
      $(document).on("turbo:load", this._load(ComponentClass));
    }
  }

  static _load(ComponentClass) {
    let $body = $('body');
    let component = new ComponentClass($body);

    if (!(component instanceof Component)) {
      throw new Error(ComponentClass.toString() + 'is not a valid class, must inherit from Component');
    }
    component.didLoad();
  }
}
