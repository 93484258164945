import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['panel'];

  connect() {
    this.tracker = "";
    this.boundTogglePanel = this.togglePanel.bind(this);
  }

  panelTargetConnected(panel) { this.togglePanel(panel); }

  togglePanel(panel) { panel.hidden = !this.enabled; }

  disable() {
    this.enabled = false;
    this.panelTargets.forEach(this.boundTogglePanel);
  }

  enable() {
    this.enabled = true;
    this.panelTargets.forEach(this.boundTogglePanel);
  }

  listen(e) {
    if (this.enabled) { return; }

    this.tracker += (e.keyCode || "").toString();

    if (this.tracker === this.konamiCode) {
      this.enable();
      this.clearTracker();
    } else if (this.konamiCode.indexOf(this.tracker)) {
      this.clearTracker();
    }
  }

  clearTracker() { this.tracker = ""; }

  set enabled(val) { localStorage.setItem("titan_debug", val.toString()); }

  get enabled() { return localStorage.getItem("titan_debug") === "true"; }

  get konamiCode() { return "38384040373937396665"; }
}
