// type - success, error, warning
function notify(message, type) {
  const event = new CustomEvent("notice", { detail: { message, type } });

  window.dispatchEvent(event);
}

export function notifySuccess(message) {
  notify(message, 'success');
}

export function notifyError(message) {
  notify(message, 'error');
}

export function notifyWarning(message) {
  notify(message, 'warning');
}
