import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "cardElement",
    "cardErrors",
    "expirationElement",
    "expirationErrors",
    "cvvElement",
    "cvvErrors",
    "paymentToken",
    "deviceData",
    "submit",
    "braintreeScript",
  ];

  static values = { key: String };

  connect() {
    const controller = this;
    this.braintreeScriptTarget.addEventListener(
      "load",
      function () {
        braintree.client.create({ authorization: controller.keyValue }) // eslint-disable-line no-undef
          .then(function (client) {
            controller._initHostedFields(client);
            controller._initDataCollection(client);
          })
          .catch(function (error) {
            console.error("Error initializing Braintree:", error); // eslint-disable-line no-console
          });
      }
    );
  }

  get fields() {
    return {
      number: { container: this.cardElementTarget },
      expirationDate: { container: this.expirationElementTarget },
      cvv: { container: this.cvvElementTarget },
    };
  }

  submit(event) {
    this.submitTarget.disabled = true;
    this.cardErrorsTarget.classList.remove('d-block');
    this.expirationErrorsTarget.classList.remove('d-block');
    this.cvvErrorsTarget.classList.remove('d-block');

    event.preventDefault();
    const controller = this;
    this.hostedFields.tokenize(function(error, payload) {
      if (error) {
        controller._validationError(error);
        controller.submitTarget.disabled = false;
      } else {
        controller.paymentTokenTarget.value = payload.nonce;
        event.target.submit();
      }
    });
  }

  _initHostedFields(client) {
    const controller = this;
    return braintree.hostedFields.create({ client: client, fields: this.fields }) // eslint-disable-line no-undef
      .then(function (hostedFields) {
        controller.hostedFields = hostedFields;
      });
  }

  _initDataCollection(client) {
    const controller = this;
    return braintree.dataCollector.create({ client: client }) // eslint-disable-line no-undef
      .then(function (dataCollector) {
        controller.deviceDataTarget.value = dataCollector.deviceData;
      });
  }

  _validationError(error) {
    if (error.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
      this.cardErrorsTarget.classList.add('d-block');
      this.expirationErrorsTarget.classList.add('d-block');
      this.cvvErrorsTarget.classList.add('d-block');
      return;
    }

    if (error.details.invalidFieldKeys.includes('expirationDate')) {
      this.expirationErrorsTarget.classList.add('d-block');
    }

    if (error.details.invalidFieldKeys.includes('number')) {
      this.cardErrorsTarget.classList.add('d-block');
    }

    if (error.details.invalidFieldKeys.includes('cvv')) {
      this.cvvErrorsTarget.classList.add('d-block');
    }
  }
}
