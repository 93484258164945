import { Controller } from "@hotwired/stimulus";

const popupDefaults = {
  toolbar: "no",
  location: "no",
  resizable: "no",
};

export default class extends Controller {
  static values = {
    url: String,
    width: { type: Number, default: 480 },
    height: { type: Number, default: 640 },
    name: { type: String, default: "GovX Offer" }
  };

  claimOffer() {
    const options = {
      ...popupDefaults,
      width: this.widthValue,
      height: this.heightValue,
      top: this.calculatePositionalY(),
      left: this.calculatePositionalX(),
    };
    const opts = Object.keys(options).map((key) => `${key}=${options[key]}`).join(",");

    window.open(this.urlValue, this.nameValue, opts);
  }

  calculatePositionalX() {
    return this.calculatePositionalCoordinate(window.top.outerWidth, window.top.screenX, this.widthValue);
  }

  calculatePositionalY() {
    return this.calculatePositionalCoordinate(window.top.outerHeight, window.top.screenY, this.heightValue);
  }

  calculatePositionalCoordinate(a, b, c) {
    return a / 2 + b - (c / 2);
  }
}
