export class Logger {
  static enabled = true;

  static error() {
    this._console('error', arguments);
  }

  static info() {
    this._console('info', arguments);
  }

  static log() {
    this._console('log', arguments);
  }

  static warn() {
    this._console('warn', arguments);
  }

  static _console(method, loggingArguments) {
    // Can't call apply on the logger in webkit, makes us have to loop over args to
    // get good looking logs
    if (this.enabled && window.console) {
      loggingArguments = Array.prototype.slice.call(loggingArguments);
      loggingArguments.forEach(function (argument) {
        window.console[method](argument);
      });
    }
  }
}

